import React from 'react';
import './solution-integration-quote.css'

function SolutionIntegrationQuote() {
    return (
        <div className='solution-integration-quote'>
            <h2>Search Less, Answer More</h2>
            <p>Support teams spend too much time searching disparate systems, previously resolved cases, team correspondence and internal systems often resulting in escalations. By seamlessly integrating with your existing stack of knowledge, and more, your team is enabled with answers at the fingertips.</p>
        </div>
    )
}

export default SolutionIntegrationQuote;