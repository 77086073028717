import React from 'react';
import './solution-integration-hero.css'
import AppButton from '../CustomComponents/app-button';
import heroImg from '../../assets/solution-integration/Smiling-call-center-agent-1039676 2.png'
import useMediaQuery from '../../hooks/useMediaQuery';

function SolutionIntegrationHero() {
    const isMobile = useMediaQuery("(max-width:745px)");
    return (
        <div className='solution-integration-hero'>
            <div className='circle-img animate'>
                <img src={heroImg} />
            </div>
            <div className='content'>
                <div className='text-content'>
                    <h1 className='animate'>Turn Apps Into {isMobile ? <span style={{color: '#08758D'}}>Answers</span> : <span>Answers</span>}</h1>
                    {isMobile ? <p className='animate'>Connect to tools that your team would typically spend time searching.</p> : <p className='animate'>Meet information where it exists, <span>everywhere</span>. Make connections to tools that your team would typically spend time searching.</p>}
                </div>
                <AppButton text="Ready to Modernize?" variant="variant-teal modernize animate" linkTo={'/get-started/'}/>
            </div>
        </div>
    )
}

export default SolutionIntegrationHero;