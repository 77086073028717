import React from 'react';;
import './solution-integration-tools.css';
import salesforce from '../../assets/solution-integration/SalesForce.png';
import zendesk from '../../assets/solution-integration/Zendesk.png';
import serviceNow from '../../assets/solution-integration/ServiceNow.png';
import sharepoint from '../../assets/solution-integration/Microsoft Sharepoint.png';
import documents60 from '../../assets/solution-integration/Document360.png';
import clickhelp from '../../assets/solution-integration/Clickhelp.png';
import confluence from '../../assets/solution-integration/Confluence.png'
import gitbook from '../../assets/solution-integration/Gitbook.png'
import guru from '../../assets/solution-integration/Guru.png'
import helpjuice from '../../assets/solution-integration/Help Juice.png'
import highspot from '../../assets/solution-integration/Highspot.png'
import jira from '../../assets/solution-integration/Jira.png'
import lessonly from '../../assets/solution-integration/Lessonly.png'
import madcap from '../../assets/solution-integration/Madcap Flare.png'
import azure from '../../assets/solution-integration/Microsoft Azure Devops.png'
import teams from '../../assets/solution-integration/Microsoft Teams.png'
import mindtouch from '../../assets/solution-integration/Mindtouch.png'
import notion from '../../assets/solution-integration/Notion.png'
import pdf from '../../assets/solution-integration/PDF OCR.png'
import skilljar from '../../assets/solution-integration/Skilljar.png'
import slack from '../../assets/solution-integration/Slack.png'
import discource from '../../assets/solution-integration/Discource.png'

function SolutionIntegrationTools() {
    const integrationTools = {
        Embedded_Integration: [
            {
                name: 'Salesforce',
                image: salesforce
            },
            {
                name: 'Zendesk',
                image: zendesk
            },
        ]
        ,
        Knowledge_Base: [
            {
                name: 'ServiceNow',
                image: serviceNow
            },
            {
                name: 'Microsoft Sharepoint',
                image: sharepoint
            },
            {
                name: 'Notion',
                image: notion
            },
            {
                name: 'Confluence',
                image: confluence
            },
            {
                name: 'Clickhelp',
                image: clickhelp
            },
            {
                name: 'Document360',
                image: documents60
            },
            {
                name: 'Gitbook',
                image: gitbook
            },
            {
                name: 'Guru',
                image: guru
            },
            {
                name: 'Help Juice',
                image: helpjuice
            },
            {
                name: 'Madcap Flare',
                image: madcap
            },
            {
                name: 'Mindtouch',
                image: mindtouch
            },
            {
                name: 'Highspot',
                image: highspot
            },
        ],
        Messaging:[
            {
                name: 'Slack',
                image: slack
            },
            {
                name: 'Microsoft Teams',
                image: teams
            },
            {
                name: 'Discource',
                image: discource
            },
            {
                name: 'Lessonly',
                image: lessonly
            },
        ],
        Other: [
            {
                name: 'Microsoft Azure Devops',
                image: azure
            },
            {
                name: 'Jira',
                image: jira
            },
            {
                name: 'PDF OCR',
                image: pdf
            },
            {
                name: 'Skilljar',
                image: skilljar
            },
        ]
    }
    return (
        <div className='solution-integration-tools'>
            <div className='integation-tool'>
                <h2>Embedded Integration</h2>
                <div className='embedded-integration'>
                    {
                        integrationTools.Embedded_Integration.map((tool) => (
                            <div className='tool embedded-tool'>
                                <img src={tool.image}/>
                                <p className='tool-name'> {tool.name} </p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='integation-tool'>
                <h2>Knowledge Base</h2>
                <div className='tools'>
                    {
                        integrationTools.Knowledge_Base.map((tool) => (
                            <div className='tool'>
                                <img src={tool.image}/>
                                <p className='tool-name'> {tool.name} </p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='integation-tool'>
                <h2>Messaging and Community</h2>
                <div className='tools'>
                    {
                        integrationTools.Messaging.map((tool) => (
                            <div className='tool'>
                                <img src={tool.image}/>
                                <p className='tool-name'> {tool.name} </p>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='integation-tool'>
                <h2>Other</h2>
                <div className='tools'>
                    {
                        integrationTools.Other.map((tool) => (
                            <div className='tool'>
                                <img src={tool.image}/>
                                <p className='tool-name'> {tool.name} </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default SolutionIntegrationTools;