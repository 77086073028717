import React from 'react'
import CtaBlock from '../components/components-group-one/CtaBlock'
import HtmlHead from '../components/html-head'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import { graphql } from 'gatsby';
import { PageData } from '../types'
import SolutionIntegrationHero from '../components/solution-integration-hero';
import SolutionIntegrationQuote from '../components/solution-integration-quote';
import SolutionIntegrationTools from '../components/solution-integration-tools';


const Integrations: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <SolutionIntegrationHero />
            <SolutionIntegrationQuote />
            <SolutionIntegrationTools />
            <CtaBlock ctaTitle='Start Today' />
            <Footer />
        </>
    )
}

export default Integrations;

export const query = graphql`
    query IntegrationsQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/integrations" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;